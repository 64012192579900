import React from "react";
import styled, { keyframes } from "styled-components";
import Progress from "components/progress";
import Timeline from "sections/about/parts/Timeline";

class TabsPart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "skills",
    };
  }

  render() {
    const TabContainer = styled.div`
      min-height: 400px;
      margin-top: 20px;
      @media (max-width: 767px) {
        margin-top: 50px;
        padding: 0 20px;
      }
    `;
    const TabSelectors = styled.div`
      display: flex;
    `;

    const ColorAnimation = keyframes`
            0%  {border-color: #04e5e5;}
            10% {border-color: #f37055;}
            20% {border-color: #ef4e7b;}
            30% {border-color: #a166ab;}
            40% {border-color: #5073b8;}
            50% {border-color: #04e5e5;}
            60% {border-color: #07b39b;}
            70% {border-color: #6fba82;}
            80% {border-color: #5073b8;}
            90% {border-color: #1098ad;}
            100% {border-color: #f37055;}
        `;
    const TabSelector = styled.button`
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      background-color: transparent;
      border: none;
      margin: 0 10px 0 0;
      border-bottom: 2px solid #fff;
      transition: 0.5s;
      &:hover,
      &.active {
        animation: ${ColorAnimation} 10s infinite alternate;
      }
      &:focus {
        outline: none;
      }
      @media (max-width: 767px) {
        font-size: 18px;
      }
    `;

    const Tabs = styled.div`
      margin-top: 20px;
    `;

    const Fade = keyframes`
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        `;

    const Tab = styled.div`
      display: none;
      animation: ${Fade} 1s forwards;
    `;

    return (
      <TabContainer>
        <TabSelectors>
          <TabSelector
            className={this.state.tab === "skills" ? "active" : ""}
            onClick={() => this.setState({ tab: "skills" })}
          >
            Skills
          </TabSelector>
          <TabSelector
            className={this.state.tab === "experience" ? "active" : ""}
            onClick={() => this.setState({ tab: "experience" })}
          >
            Works
          </TabSelector>
          <TabSelector
            className={this.state.tab === "education" ? "active" : ""}
            onClick={() => this.setState({ tab: "education" })}
          >
            Studies
          </TabSelector>
        </TabSelectors>
        <Tabs>
          <Tab
            style={{
              display: this.state.tab === "skills" ? "block" : "none",
            }}
          >
            <Progress value={80} text="Big Data" />
            <Progress value={100} text="DevOps (k8s+CI)" />
            <Progress value={99} text="Backend (Blue Stack TS,Py,Go)" />
            <Progress value={99} text="Frontend (React)" />
            <Progress value={66} text="Machine Learning" />
          </Tab>
        </Tabs>
        <Tabs>
          <Tab
            style={{
              display: this.state.tab === "experience" ? "block" : "none",
            }}
          >
            <Timeline
              data={{
                "2019 - Now": {
                  title: "AI Scientist",
                  institution: "Stoqey",
                  description: "",
                },
                // "2001 - 2005": {
                //   title: "Junior Developer",
                //   institution: "Lorem Softwares",
                //   description:
                //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting",
                // },
                // "2007 - 2010": {
                //   title: "Senior Developer",
                //   institution: "Ipsum Technologies",
                //   description:
                //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting",
                // },
                // "2012 - 2018": {
                //   title: "Data Scientist",
                //   institution: "Dolor AI",
                //   description:
                //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting",
                // },
              }}
            />
          </Tab>
          <Tab
            style={{
              display: this.state.tab === "education" ? "block" : "none",
            }}
          >
            <Timeline
              data={{
                "2017 - Now": {
                  title: "PhD in Data Science",
                  institution: "Google Search University",
                  description:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting",
                },
                //   "2019 - Now": {
                //     title: "PhD in Data Science",
                //     institution: "Dolor University",
                //     description:
                //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting",
                //   },
                // "2001 - 2005": {
                //   title: "High School Degree",
                //   institution: "Lorem High School",
                //   description:
                //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting",
                // },
                // "2007 - 2010": {
                //   title: "College Degree",
                //   institution: "Ipsum College",
                //   description:
                //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting",
                // },
                // "2012 - 2018": {
                //   title: "B.Sc in Computer Science",
                //   institution: "Dolor University",
                //   description:
                //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting",
                // },
                // "2018 - Now": {
                //   title: "PhD in AI",
                //   institution: "Amet University",
                //   description:
                //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting",
                // },
              }}
            />
          </Tab>
        </Tabs>
      </TabContainer>
    );
  }
}

export default TabsPart;
